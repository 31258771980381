/* Countdown Timer script */
(function ($) {
  'use strict';

  function getTimeDifference(endTiming) {
    var timer = endTiming - new Date();
    var seconds = Math.floor((timer / 1000) % 60);
    var minutes = Math.floor((timer / 1000 / 60) % 60);
    var hours = Math.floor((timer / (1000 * 60 * 60)) % 24);
    var days = Math.floor(timer / (1000 * 60 * 60 * 24));

    return {
      total: timer,
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };
  }

  function onTimerComplete(clockObj) {
    var textComplete = '';
    var $textCompleteElement = clockObj.querySelector('.js-textcomplete');
    var $textComp;

    if (typeof $textCompleteElement !== 'undefined' && $textCompleteElement) {
      textComplete = $textCompleteElement.innerHTML;
    }

    if (textComplete.length > 0) {
      $textComp = $(clockObj).find('.js-textcomplete');

      if ($textComp.length > 0) {
        // Hide the clock and show the complete text
        $textComp.innerHTML = textComplete;
        $(clockObj).find('.js-coming-soon').addClass('hidden');
        $(clockObj).find('.js-watch').addClass('hidden');
        $(clockObj).find('.js-tag-line').addClass('hidden');
        $textComp.removeClass('hidden');
      }
    }
  }

  function initializeClock(clockElm, endTiming) {
    var clock = clockElm;
    var daysElem;
    var hoursElem;
    var minutesElem;
    var secondsElem;
    var timer;
    var timeInterval;
    var timerDays;
    var timerHours;
    var tminute;
    var tday;
    var thrs;
    var tmin;
    var tsec;

    if (typeof clock === 'undefined' || !clock) {
      return;
    }
    daysElem = clock.querySelector('.js-days');
    hoursElem = clock.querySelector('.js-hours');
    minutesElem = clock.querySelector('.js-minutes');
    secondsElem = clock.querySelector('.js-seconds');
    timer = getTimeDifference(endTiming);

    function updateClock() {
      // On timer complete
      if (timer.total < 1000) {
        clearInterval(timeInterval);
        onTimerComplete(clock);

        return;
      }

      // Updating time
      if (timer.seconds === 0) {
        timerDays = timer.hours === 0 ? timer.days - 1 : timer.days;
        timer.days = timer.days === 0 ? 0 : timerDays;
        timerHours = timer.minutes === 0 ? timer.hours - 1 : timer.hours;
        timerHours = timer.hours === 0 ? 23 : timerHours;
        timer.hours = timer.days === 0 && timer.hours === 0 ? 0 : timerHours;
        tminute = timer.minutes === 0 ? 59 : timer.minutes - 1;
        timer.minutes = timer.hours === 0 && timer.minutes === 0 ? 0 : tminute;
        timer.seconds = 60;
      }
      timer.seconds -= 1;
      timer.total -= 1000;
      tday = timer.days < 10 ? '0' + timer.days : timer.days;
      thrs = timer.hours < 10 ? '0' + timer.hours : timer.hours;
      tmin = timer.minutes < 10 ? '0' + timer.minutes : timer.minutes;
      tsec = timer.seconds < 10 ? '0' + timer.seconds : timer.seconds;

      if (daysElem) {
        daysElem.innerHTML = tday;
      }
      if (hoursElem) {
        hoursElem.innerHTML = thrs;
      }
      if (minutesElem) {
        minutesElem.innerHTML = tmin;
      }
      if (secondsElem) {
        secondsElem.innerHTML = tsec;
      }
    }

    updateClock();
    timeInterval = setInterval(updateClock, 1000);
  }

  Drupal.behaviors.CountdownTimerV1 = {
    init: function (context) {
      $('.js-countdown-clock', context).each(function (obji, objelm) {
        var fullDateElement = objelm.querySelector('.js-full-date');
        var fullDate;
        var endTime;

        if (typeof fullDateElement !== 'undefined' && fullDateElement) {
          fullDate = fullDateElement.innerHTML.replace(/&nbsp;/gu, '');
          endTime = new Date(fullDate);

          initializeClock(objelm, endTime);
        }
      });
    },
    attach: function (context) {
      this.init(context);
    }
  };
})(jQuery);
